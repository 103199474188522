import React from "react"
import { graphql } from "gatsby"
import Head from "../components/head"
import Layout from "../components/layout"
import Intro from "../components/intro"
import Features3Grid from "../components/features-3-grid"
import CallToACtion from "../components/cta"

export const query = graphql`
  query {
    allFile(filter: {extension: {eq: "md"}, name: {eq: "mitarbeit"}}) {
      edges {
        node {
          name
          childMarkdownRemark {
            frontmatter {
              title
              layout
              intro {
                text
                title
              }
              gridCols3 {
                image
                text
                button {
                  text
                  link
                }
              }
              cta {
                subtitle
                title
                text
                button {
                  text
                  link
                }
              }
            }
            html
          }
        }
      }
    }
  }
`

export default function Jobs(props) {
  const markdown = props.data.allFile.edges[0].node.childMarkdownRemark;
  const data = markdown.frontmatter;
  const html = markdown.html;

  return (
    <Layout>
      <Head title={data.title} robots="index, follow" />
      <Intro subtitle={data.title} title={data.intro.title} titleHeading="h1" text={data.intro.text} />
      <Features3Grid gridCols3={data.gridCols3} />
      <div className="my-16 sm:my-24 lg:my-32">
        <div className="px-4 sm:px-6 lg:px-8">
          <div
            className="prose prose-petrol prose-md md:prose-lg text-petrol prose-headings:text-petrol prose-h1:font-serif prose-h1:font-normal prose-h2:font-serif prose-h2:font-normal prose-h3:font-serif prose-h3:font-normal prose-a:text-petrol prose-blockquote:text-petrol prose-strong:text-petrol mx-auto max-w-4xl mt-8 md:mt-12 lg:mt-16"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
      <CallToACtion subtitle={data.cta.subtitle} title={data.cta.title} text={data.cta.text} button={data.cta.button} />
    </Layout>
  )
}
